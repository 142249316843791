<!--
 * @Author: lbw
 * @Date: 2022-08-13 12:51:51
 * @LastEditors: lbw
 * @LastEditTime: 2022-08-14 19:26:03
 * @Description:
-->

<template>
  <div class="warp" v-loading="loading" :element-loading-text="$t('message.Loadingdesperately')"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="cententbox" ref="pageh">
      <!--title-->
      <!-- <div class="persontextbox">
                <span class="persontext">用户历史轨迹</span>
              </div> -->
      <!--搜索栏-->
      <div class="heaedercl disflex flexal">
        <div>
          <el-button v-show="showbtn" type="primary" @click="backbtn">{{ $t('message.refund') }}</el-button>
        </div>
        <div class="disflex flexal">
          <span class="marright marginleftcl">{{ $t('message.users') }}：</span>
          <el-select v-model="Uid" :placeholder="$t('message.Pleaseselectauser')" class="inputwid160" filterable>
            <el-option v-for="item in userlists" :key="item.User_ID" :label="item.User_Name" :value="item.User_ID">
            </el-option>
          </el-select>

          <div>
            <span class="marright marginleftcl">{{ $t('message.startingtime1') }}：</span>
            <el-date-picker v-model="vv1" type="datetime" align="right" :picker-options="pickerOptions"
              :placeholder="$t('message.Pleaseselectadateandtime')">
            </el-date-picker>
          </div>

          <div>
            <span class="marright marginleftcl">{{ $t('message.EndTime') }}：</span>
            <el-date-picker v-model="vv2" type="datetime" align="right" :picker-options="pickerOptions"
              :placeholder="$t('message.Pleaseselectanendtime')">
            </el-date-picker>
            <!-- <el-button type="primary" @click="search1">开始</el-button> -->
          </div>
          <div style="margin: 0px 10px">
            <el-button type="primary" @click="search">{{ $t('message.search') }}</el-button>
          </div>
        </div>
      </div>
      <!--内容-->
      <!--百度地图 -->
      <div class="baidu-api" v-show="mapType == 'baidu'" id="allmap"></div>
      <!--谷歌地图-->
      <div class="baidu-api" v-show="mapType == 'Wgs84'" v-loading="!gooleWc"
        :element-loading-text="$t('message.Loadingdesperately')" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)" id="googlemap"></div>
      <!-- 切换地图 -->
      <!-- <div class="map_type">
        <img v-if="mapType == 'Wgs84'" @click="cutMap('baidu')" src="@/assets/map/baidu.png" alt="">
        <img v-else @click="cutMap('Wgs84')" src="@/assets/map/google.png" alt="">
      </div> -->
    </div>
  </div>
</template>

<script></script>
<script>
import beasconfig from "@/api/beasconfig.js"
import {queryUserName} from "@/administration/history.js";
import axios from 'axios'
import qs from "qs";
import moment from 'moment';
const gpsUrlapi = beasconfig.gpsUrlapi;
// var apiUrl = JSON.parse(sessionStorage.getItem("http"));
// let item = apiUrl.find(item => item.name == 'nginx');
// let api = item.http;
// let port = item.port;
export default {
  name: "groupGps",
  data() {
    return {
      // status: '',
      // tiemvalue1: '',
      // tiemvalue2: '',
      // uId: '',

      // btime1:'',
      // btime2:'',
      // bvv1:'',
      // bvv2:'',
      // bid:'',
      // statusSearch:false,
      /*三个月以前*/
      pickerOptions: {
        disabledDate(time) {
          let curDate = (new Date()).getTime();
          let three = 90 * 24 * 3600 * 1000;
          let threeMonths = curDate - three;
          return time.getTime() > Date.now() || time.getTime() < threeMonths;
        }
      },
      showbtn: true,
      time1: '',
      time2: '',
      vv1: '',
      vv2: '',
      Uid: '',
      isOrientation: '',
      userlists: [],
      loading:false,
      pslist:[],
      timeNum: 0,
      mapType: 'Wgs84',
      gooleWc: false,
      gooleCover: [],  //覆盖物数组
      language: localStorage.getItem('languageStorage') || 'en'
    }
  },
  mounted() {
    this.baiduMap();
    this.initMap();
  },
  watch: {
    uId(val) {
      if (val == -1) {
        this.uId = '';
      }
    }
  },
  methods: {
    cutMap(type) {   //切换地图
      if (this.mapType != type) {
        this.mapType = type;
        if (this.gooleWc == false) {  //判断地图是否有加载出来
          this.initMap();
        }
        this.getTrajectoryMethod();
      }
    },
    /*历史轨迹*/
    getTrajectoryMethod() {
      // console.log('this.statusSearch', this.statusSearch)
      var tiem1 = moment(this.vv1).format("YYYY-MM-DD");
      var tiem2 = moment(this.vv2).format("YYYY-MM-DD");
      var date1 = moment(this.vv1).valueOf();
      var data2 = moment(this.vv2).valueOf();
      // console.log(date1,data2);
      this.bid = this.uId;
      // let daTime = (3600 * 1000 * 8);
      let startDateTamp = moment(this.vv1).valueOf();
      let endDateTamp = moment(this.vv2).valueOf();
      let vv1 = moment(startDateTamp).format("YYYY-MM-DD HH:mm:ss");  //海外需要加8小时
      let vv2 = moment(endDateTamp).format("YYYY-MM-DD HH:mm:ss");  //海外需要加8小时
      /!*判断是否为同一天*!/
      if (this.vv1 == "" || this.vv1 == null || this.vv2 == "" || this.vv2 == null) {
            this.$MessageWarning(this.$t('message.message.Pleaseselectastartandendtime'));
            return
       }else if(date1 > data2){
        this.$MessageWarning(this.$t('message.message.endTimeNoStarttime'));
        return
       }else if (tiem1 == tiem2) {
        this.time1 = moment(this.vv1).valueOf();
        this.time2 = moment(this.vv2).valueOf();
        this.vv1 = moment(this.vv1).format("YYYY-MM-DD HH:mm:ss");
        this.vv2 = moment(this.vv2).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.$MessageWarning(this.$t('message.message.Thestartandendtimesmustbeonthesameday'));
        return
      }
      // if(this.statusSearch){
      //   var params = {
      //     Uid: this.bid,
      //     CorrdinateType: "baidu",
      //     startDateTime: this.bvv1,
      //     endDateTime: this.bvv2,
      //     startDateTamp: this.btime1, //时间戳13位
      //     endDateTamp: this.btime2
      //   };
      // }else {
      this.loading = true;

      var params = {
        Uid: this.Uid,
        CorrdinateType: "Wgs84",
        startDateTime: vv1,
        endDateTime: vv2,
        startDateTamp: startDateTamp, //时间戳13位
        endDateTamp: endDateTamp,
        // positionType:2
      };
      // }
      
      if (!this.isOrientation) {
        params.positionType = 2;
      }
      axios.post(gpsUrlapi + '/shanli/gps/api/trace/gethistory?', qs.stringify(params), {
        headers: beasconfig.tokenSl
      }).then(res => {
        // console.log('轨迹数据', res.data.Data)
        if (res.data.Status == 1 && res.data.Data.length > 0) {
          var pots = [];
          var rectifyPoints = [];
          res.data.Data.forEach((el) =>{
            if (el.Lng && el.Lat) {

              rectifyPoints.push({
                "coord_type_input": "bd09ll",
                "latitude": el.Lat,
                "longitude": el.Lng,
                "loc_time": Number(el.Rtimestamp)/ 1000,
                "speed": el.Speed
              });
              if (this.mapType == "baidu") {
                pots.push(new BMap.Point(el.Lng, el.Lat));
              } else {
                pots.push({ lat: el.Lat, lng: el.Lng },);
              }
            }
          })
          this.pslist = pots;
          this.drawHistroyTrace(pots,rectifyPoints);
          this.loading = false;
          // this.rectifyTrace(rectifyPoints, (points) => {
          //   var pots = [];
          //   var dwArr = [];
          //   points.forEach(function (el) {
          //     if (el.longitude && el.latitude) {
          //       pots.push(new BMap.Point(el.longitude, el.latitude));
          //       console.log(el)
          //       dwArr.push(el);
          //     }
          //   });
          //   this.pslist = pots;
          //   this.drawHistroyTrace(pots,dwArr);
          // });
        } else {
          this.loading = false;
          this.$MessageWarning(this.$t('message.noData'));
        }

      })
    },
    // 轨迹纠偏
    rectifyTrace(points, cb) {
      // isBindRoad  是否绑路 0是不绑路 1是绑路
      // var _param = 'ak=UZfTF1PQc3R9GaMg7GEaADbWw1h9udhb&point_list=' + JSON.stringify(points) + '&rectify_option=need_mapmatch:1|denoise_grade:5|transport_mode:auto|vacuate_grade:0';
      var _param = `ak=UZfTF1PQc3R9GaMg7GEaADbWw1h9udhb&point_list=${JSON.stringify(points)}&rectify_option=need_mapmatch:${this.$store.state.userState.isBindRoad}|transport_mode:auto|denoise_grade:5|vacuate_grade:0`;
      axios.post(`${beasconfig.xinextendUrlapi}/gps/getBaiDu`, qs.stringify({
        url: "http://api.map.baidu.com/rectify/v1/track",
        reqType: 1,
        param: _param
      }), {
        headers: beasconfig.tokenSl
      }).then(res => {
        // console.warn(res)
        const data = JSON.parse(res.data.data)
        cb && cb(data.points)
        // console.log('轨迹点', res)
        this.loading = false;
      })

    },
    clearOverlays() {   //清除所添加覆盖物
      if(this.gooleCover.length > 0){
        this.gooleCover.forEach(item => {
          item.setMap(null);
        })
        this.gooleCover = [];
      }
      this.map.clearOverlays();
    },
    // 根据点的数组自动调整缩放级别
    setZoom(pois) {
      if (this.mapType == 'Wgs84') {
        var latlngbounds = new google.maps.LatLngBounds();  //根据点的数组自动调整缩放级别
        pois.forEach(item => {
          latlngbounds.extend(item);
        })
        this.maps.fitBounds(latlngbounds);
        this.maps.panToBounds(latlngbounds);
      }
    },
    showMarkerLabel(id,type){  //显示盒子
        var t = document.getElementById(`dw${id}`);//选取label为百度baidu的id进行隐藏
        t.style.display =type == 1?'block':'none';// 以块级样式隐藏
    },
    // 绘制轨迹
    drawHistroyTrace(pois,dwArr) {
      // 先清除上次画的轨迹
      // 先清除上次画的轨迹
      this.clearOverlays();//先清除上次画的轨迹
      if (this.mapType == 'baidu') {
        this.map.setViewport(pois);
      }
      var gps ={}; //找出相同的定位点
      dwArr.forEach((item,index) =>{
        var pint = item.loc_time;
        if(!gps[pint]){    //判断是否有这个数组
          var html = `<div class='bd_circular mouse_dw'><div id="dw${index}" class="ds_box ds_none">${this.$moment(item.loc_time*1000).format("YYYY-MM-DD HH:mm:ss")}<div class="big" style="bottom: -12px;" ><div class='small'></div></div></div></div>`;
          gps[pint]= [item];
          if(this.mapType == "Wgs84"){
            var position = new google.maps.LatLng(item.latitude, item.longitude);
            const markerLabel = new MarkerWithLabel({
                id:index,
                position: position,
                icon: {
                  url: ' '
                },
                map: this.maps, //地图实例
                labelContent: html, //label的内容
                // 调整框框
                labelAnchor: new google.maps.Point(4, 8),
                labelStyle: {
                  padding: "0px",
                  position:'absolute',
                  // color: '#ffffff',
                  // backgroundColor: 'rgb(103, 197, 197)',
                  // borderRadius: '5px',
                  fontWeight: '400',
                  opacity: 1
                },
                labelZIndex:index,
                zIndex:100000+index
            });
              markerLabel.addListener("mouseover", () => {
                  this.showMarkerLabel(markerLabel.id,1);
              });
              markerLabel.addListener("mouseout", () => {
                  this.showMarkerLabel(markerLabel.id,0);
              });
              this.gooleCover.push(markerLabel)
          }else{
            var opts = {
            // position: item, // 指定文本标注所在的地理位置
              position: new BMap.Point(item.longitude, item.latitude),
              offset: new BMap.Size(-7, -6) // 设置文本偏移量
            };
            var label = new BMap.Label(html, opts);
                  // 自定义文本标注样式
                  label.setStyle({
                    color: "#000",
                    borderRadius: "5px",
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    padding: "0px",
                    fontSize: "16px",
                    fontFamily: "微软雅黑",
              });
              label.id = index;
              label.addEventListener("mouseover", (e) => {
                let id = e.currentTarget.id;
                this.showMarkerLabel(id,1);
              });
              label.addEventListener("mouseout", (e) => {
                 let id = e.currentTarget.id;
                 this.showMarkerLabel(id,0);
              });
              this.map.addOverlay(label);
          }
        }
      })
      this.drawRoute(pois);
      this.addMarker({
        lng: pois[0].lng,
        lat: pois[0].lat
      }, 'start');
      this.addMarker({
        lng: pois[pois.length - 1].lng,
        lat: pois[pois.length - 1].lat
      }, 'end');
      this.map.getPanes().labelPane.style.zIndex = 700;//修改图层z-index
      this.map.getPanes().markerMouseTarget.style.zIndex = 500;//修改图层z-index
    },
    // 绘制两点之间的路径
    drawRoute(pois) {
      // console.log(pois)
      if(this.mapType == "baidu"){
        var polyline = new BMap.Polyline(pois, {
          enableEditing: false,//是否启用线编辑，默认为false
          enableClicking: false,//是否响应点击事件，默认为true
          // icons: [icons],
          strokeWeight: '4',//折线的宽度，以像素为单位
          strokeOpacity: 1,//折线的透明度，取值范围0 - 1
          strokeColor: "#999" //折线颜色
        });
        var polyline2 = new BMap.Polyline(pois, {
          enableEditing: false,//是否启用线编辑，默认为false
          enableClicking: true,//是否响应点击事件，默认为true
          // icons: [icons],
          strokeWeight: '2',//折线的宽度，以像素为单位
          strokeOpacity: 1,//折线的透明度，取值范围0 - 1
          strokeColor: "#4cdd25" //折线颜色
        });
        this.map.addOverlay(polyline);          //增加折线
        this.map.addOverlay(polyline2);          //增加折线
      }else{
        const flightPath = new google.maps.Polyline({
          path: pois,
          geodesic: true,
          strokeColor: "#999",
          strokeOpacity: 1.0,
          strokeWeight: 6,
        });

        const flightPath2 = new google.maps.Polyline({
          path: pois,
          geodesic: true,
          strokeColor: "#4cdd25",
          strokeOpacity: 1.0,
          strokeWeight: 4,
        });

        flightPath.setMap(this.maps);
        flightPath2.setMap(this.maps);
        this.gooleCover.push(flightPath, flightPath2)
        this.setZoom(pois);
      }
    },
    // 创建覆盖物
    addMarker(point, type) {
      var pos = point;
      if (pos) {
        var pt = new BMap.Point(pos.lng, pos.lat);

        var iconSrc = '';
        if (type == 'start') {
            iconSrc = require(this.language == 'zh' ? '../../assets/map/start.png' : '../../assets/map/start_hw.png');
        }
        if (type == 'end') {
            iconSrc = require(this.language == 'zh' ? '../../assets/map/end.png' : '../../assets/map/end_hw.png');
        }
        if(this.mapType == "baidu"){
          var myIcon = new BMap.Icon(iconSrc, new BMap.Size(31.5, 36), {
            anchor: new BMap.Size(15.5, 36)
          });

          myIcon.setImageSize(new BMap.Size(31.5, 36));
          var marker = new BMap.Marker(pt, { icon: myIcon });  // 创建标注

          this.map.addOverlay(marker);              // 将标注添加到地图中
        }else{
          var position = new google.maps.LatLng(pos.lat, pos.lng);
          // const marker = 
          const marker = new google.maps.Marker({
            markerId: 'Marker1',
            position,
            map: this.maps,
            icon: {
              url: iconSrc, //most point
              size: new google.maps.Size(63, 73),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(16, 36),
              scaledSize: new google.maps.Size(31.5, 36)
            }
          });
          this.gooleCover.push(marker)
        }
      }
    },
    //  谷歌地图
    initMap() {
      if (window.googleMap) {  //window.googleMap有值就代表地图API已经加载完成
        this.timeNum = 0;
        try {
          this.maps = new google.maps.Map(document.getElementById("googlemap"), {
            zoom: 10,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            //地图中心点，这里我以第一个数据的经纬度来设置中心点
            center: { lat: 38.903804230612835, lng: -77.0365677652717 },
            disableDefaultUI: false,
            zoomControl: false,
            maxZoom: 17  //18  //最大缩放点
          });
          this.gooleWc = true;
          this.getTrajectoryMethod();
        } catch (error) {
          this.gooleWc = false;
        }
      } else {
        setTimeout(() => {
          this.timeNum += 1;
          if (this.timeNum <= 10) {
            this.initMap();
          }
        }, 1000)
      }
    },
    /*百度地图*/
    baiduMap() {
      var map = new BMap.Map('allmap', { enableMapClick: false });
      map.centerAndZoom("北京", 10);
      map.enableScrollWheelZoom();
      /*比例尺*/
      map.addControl(new BMap.NavigationControl({
        type: BMAP_NAVIGATION_CONTROL_LARGE,
        anchor: BMAP_ANCHOR_BOTTOM_LEFT,
        offset: new BMap.Size(20, 50)
      }));// 添加比例尺控件
      this.map = map
    },
    /*调度对讲*/
    backbtn() {
        // this.$router.go(-1);
        if(this.$route.name == 'trajectory'){
          this.$router.push(`/intercom/baiduMap`);
        }else{
          this.$router.go(-1);
        }
    },
    /*搜索*/
    search() {
      /*if(this.tiemvalue1 || this.tiemvalue2 || (this.uId && this.uId != -1)){
        this.statusSearch = true;
        if(!this.tiemvalue1){
          this.$MessageWarning('请选择开始时间');
          return;
        }
        if(!this.tiemvalue2){
          this.$MessageWarning('请选择结束时间');
          return;
        }
        var tiem1 =moment(this.tiemvalue1).format("YYYY-MM-DD");
        var tiem2 = moment(this.tiemvalue2).format("YYYY-MM-DD");
        this.bid = this.uId;
        /!*判断是否为同一天*!/
        if(tiem1 == tiem2){
          this.btime1 = moment(this.tiemvalue1).valueOf();
          this.btime2 = moment(this.tiemvalue2).valueOf();
          this.bvv1 = moment(this.tiemvalue1).format("YYYY-MM-DD HH:mm:ss");
          this.bvv2 = moment(this.tiemvalue2).format("YYYY-MM-DD HH:mm:ss");
        }else {
          this.$MessageWarning('开始时间和结束时间必须为同一天');
          return
        }
      }else {
        this.statusSearch = false;
        this.tiemvalue1 = '';
        this.tiemvalue2 = '';
        this.uId = '';
      }*/
      this.clearOverlays();//先清除上次画的轨迹
      this.getTrajectoryMethod();
    },
    search1(){
      // this.map.clearOverlays();//清楚覆盖物
  //     var pl = new BMap.Polyline(this.pslist,{
  //     strokeColor:'var(--main-color)',
  //     strokeWeight:2,
  //     strokeStyle:'dashed'
  //   });
  //   var trackAni = new BMapGLLib.TrackAnimation(this.map, pl, {
  //     overallView: true, // 动画完成后自动调整视野到总览
  //     tilt: 30,          // 轨迹播放的角度，默认为55
  //     duration: 20000,   // 动画持续时长，默认为10000，单位ms
  //     delay: 3000        // 动画开始的延迟，默认0，单位ms
  //  });
  //   trackAni.start(); 
      let arr = [{
          lng: 113.763924,
          lat : 22.938634
        },
        {
          lng: 113.759621,
          lat : 22.933625
        },
        {
          lng: 113.76654,
          lat : 22.934174
        },
        {
          lng: 113.766558,
          lat : 22.9329161
        },
        {
          lng: 113.766558,
          lat : 22.932916
        },
        {
          lng: 113.768745,
          lat : 22.93173
        },
        {
          lng: 113.76945,
          lat : 22.930731
        },
        {
          lng: 113.772022,
          lat : 22.93014
        },
        {
          lng: 108.952789,
          lat : 34.348938
        }];
        var PointArr = []; 
      arr.forEach(el =>{
        PointArr.push( new BMap.Point(el.lng, el.lat))
      })
// 实例化一个驾车导航用来生成路线
      
        //this.pslist;
        // console.log(PointArr)
        
        // var pathList = [];
        //     PointArr.forEach(item =>{
        //            if(pathList.length > 0){
        //               pathList.filter(row =>{
        //                 if(row.lat != item.lat && row.lng != item.lng){
        //                   console.log(row.lat,item.lat)
        //                   console.log(row.lng,item.lng)
        //                   pathList.push(item);
        //                 }
        //               })
        //            }else{
        //             pathList.push(item);
        //            }
        //       })
        //       console.log(pathList)
        //       PointArr = pathList;
        this.map.centerAndZoom(PointArr, 13);// 根据经纬度显示地图的范围
        this.map.setViewport(PointArr);// 根据提供的地理区域或坐标设置地图视野
        // new BMap.Point(PointArr[0].lng, PointArr[0].lat)
        addStartMarker(PointArr[0],'起点',this.map);
        var carMk;//先将终点坐标展示的mark对象定义
        //小车行驶图标
        var drivingPoint = new BMap.Icon('http://developer.baidu.com/map/jsdemo/img/car.png', new BMap.Size(52,26), {
            anchor : new BMap.Size(27, 13),
            imageSize:new BMap.Size(52,26)
        });
        //终点图标
        let img = require('../../assets/map/end.png');
        var terminalPoint = new BMap.Icon(img, new BMap.Size(45,45), {
            anchor : new BMap.Size(20, 45),
            imageSize:new BMap.Size(45,45)
        });
        var i = 0;
        var interval = setInterval(function () {
            if (i >= PointArr.length) {
                clearInterval(interval);
                return;
            }
            drowLine(this.map,PointArr[i],PointArr[i+1]);//画线调用
            i = i + 1;
        }, 1000);
        let that = this;
        // 划线
        function drowLine(map,PointArr,PointArrNext) {
            if(PointArrNext!=undefined) {
                var polyline = new BMap.Polyline(
                    [
                         PointArr,
                        // new BMap.Point(PointArrNext.lng, PointArrNext.lat)
                        PointArrNext
                    ],
                    {
                        strokeColor: "red",
                        strokeWeight: 5,
                        strokeOpacity: 1
                    });   //创建折线
                that.map.addOverlay(polyline);
                addMarkerEnd(PointArrNext, '小车行驶', map, PointArrNext, PointArr);//添加图标
            }else {
                // addMarkerEnd(PointArr, '终点', this.map);//添加终点图标
            }
        }
        //添加起始图标
        function addStartMarker(point, name,mapInit) {
            if(name=="起点"){
                let img = require('../../assets/map/start.png');
                var myIcon = new BMap.Icon(img, new BMap.Size(45,45),{
                    anchor: new BMap.Size(20, 45),//这句表示图片相对于所加的点的位置mapStart
                    imageSize:new BMap.Size(45, 45)//图标所用的图片的大小，此功能的作用等同于CSS中的background-size属性。可用于实现高清屏的高清效果
                    // offset: new BMap.Size(-10, 45), // 指定定位位置
                    // imageOffset: new BMap.Size(0, 0 - 10 * 25) // 设置图片偏移
                });
                window.marker = new BMap.Marker(point,{icon:myIcon});  // 创建标注
                mapInit.addOverlay(marker);               // 将标注添加到地图中
                //marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
            }
        }
        //添加行驶和终点图标
        function addMarkerEnd(point, name,mapInit,trackUnit,prePoint) {
            if(name=="小车行驶"){
                if(carMk){//先判断第一次进来的时候这个值有没有定义，有的话就清除掉上一次的。然后在进行画图标。第一次进来时候没有定义也就不走这块，直接进行画图标
                  that.map.removeOverlay(carMk);
                }
                carMk = new BMap.Marker(point,{icon:drivingPoint});  // 创建标注
                // carMk.setRotation(trackUnit.route);//trackUnit.route
                // //getAngle(point,prePoint);// js求解两点之间的角度
                // carMk.setRotation(getAngle(point,prePoint)-90);// 旋转的角度
                that.map.addOverlay(carMk);               // 将标注添加到地图中
                // carMk.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
            }else {
                that.map.removeOverlay(carMk);
                carMk = new BMap.Marker(point,{icon:terminalPoint});  // 创建标注
                that.map.addOverlay(carMk);
            }
        }
        //获得角度的函数
        function getAngle(n,next){
            var ret
            var w1 = n.lat/180 * Math.PI
            var j1 = n.lng/180 * Math.PI

            var w2 = next.lat/180 * Math.PI
            var j2 = next.lng/180 * Math.PI

            ret = 4 * Math.pow(Math.sin((w1 - w2) / 2), 2) - Math.pow(Math.sin((j1 - j2) / 2) * (Math.cos(w1) - Math.cos(w2)), 2);
            ret = Math.sqrt(ret);

            // var temp = Math.sin(Math.abs(j1 - j2) / 2) * (Math.cos(w1) + Math.cos(w2));
            var temp = Math.sin((j1 - j2) / 2) * (Math.cos(w1) + Math.cos(w2));
            // console.log(temp)
            ret = ret/temp;

            ret = Math.atan(ret) / Math.PI * 180 ;
            ret += 90;

            // 这里用如此臃肿的if..else是为了判定追踪单个点的具体情况,从而调整ret的值
            if(j1-j2 < 0){
                // console.log('j1<j2')
                if(w1-w2 < 0){
                    // console.log('w1<w2')
                    ret;
                }else{
                    // console.log('w1>w2')
                    ret = -ret+180;
                }
            }else{
                // console.log('j1>j2')
                if(w1-w2 < 0){
                    // console.log('w1<w2')
                    ret = 180+ret;
                }else{
                    // console.log('w1>w2')
                    ret = -ret;
                }
            }
            return ret ;
        }
                    //  var pts = this.pslist;//你获取到的一系列点的数组(通过gps或其他接口)
                    //  var paths = this.pslist.length;    //获得有几个点
                    //  var pathList = [];
                    //  console.log(pts)
                    //  pts.forEach(item =>{
                    //     let bool = pathList.some(a =>a.lng == item.lng);
                    //     if(!bool){
                    //       pathList.push(item);
                    //     }
                    //  })
                    //  console.log(pathList)
                    //  var carMk = new BMap.Marker(pathList[0]);
                    //  this.map.addOverlay(carMk);
                    //  function resetMkPoint(i){
                    //         carMk.setPosition(pathList[i]);//重新设置marker的position
                    //         if(i < pathList.length){
                    //                setTimeout(function(){
                    //                       i++;
                    //                       console.log(i)
                    //                       resetMkPoint(i);
                    //                },1000);
                    //         }
                    //  }
                    //  setTimeout(function(){
                    //         resetMkPoint(1);
                    //  },100)
    },
    userlist() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let parmas = {
         uId:userInfo.id
      }
      queryUserName(parmas).then(res => {
        if (res.data.code == 200) {
          let users = res.data.data;
          if(users && users.length > 0){
            this.userlists = users.filter(item => item.user_isactive == 1);  //过滤出为删除的用户
          }
          // this.userlists.unshift({
          //   'User_ID': -1,
          //   'User_Name': '请选择用户'
          // })
        } else if (res.code == 40102) {

        } else {
          this.$MessageWarning(res.data.msg);
        }
      })
    },
  },
  created() {
    /* 是否开启定位功能 */
    this.isOrientation = JSON.parse(this.$route.query.isOrientation)
    /*用户id*/
    this.Uid = parseInt(this.$route.query.uid);
    this.time1 = this.$route.query.tiem1;
    this.time2 = this.$route.query.tiem2;
    this.vv1 = this.$route.query.vv1;
    this.vv2 = this.$route.query.vv2;
    /*查用户*/
    this.userlist()
    /*获取轨迹*/
    // if(this.$store.state.userState.isLoading){
    //   this.getTrajectoryMethod();
    // }else{
    //   setTimeout(()=>{
    //     this.getTrajectoryMethod();
    //   },1000)
    // }
  },
  watch: {
        $route(){
          this.isOrientation = JSON.parse(this.$route.query.isOrientation)
          /*用户id*/
          this.Uid = parseInt(this.$route.query.uid);
          this.time1 = this.$route.query.tiem1;
          this.time2 = this.$route.query.tiem2;
          this.vv1 = this.$route.query.vv1;
          this.vv2 = this.$route.query.vv2;
          /*查用户*/
          this.userlist()
          this.clearOverlays();//先清除上次画的轨迹
          /*获取轨迹*/
          this.getTrajectoryMethod();
        }
    }
}
</script>

<style lang="less" scoped>
.warp {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .baidu-api {
    width: 100%;
    height: calc(100vh - 160px);
    margin-top: 10px;
  }

  .cententbox {
    width: 100%;
    padding: 10px 10px 6px 10px;

    // height: calc(100% - 20px);
    .persontextbox {
      height: 42px;
      border-bottom: 1px solid #ddd;

      .persontext {
        display: inline-block;
        height: 41px;
        line-height: 41px;
        padding: 0 20px;
        font-size: 15px;
        color: #888;
        border: 1px solid #ddd;
        border-bottom-color: #e2e2e2;
        border-top-right-radius: #f0f0f0;
        border-top-left-radius: 5px;
        background-color: #e2e2e2;
      }
    }

    .disflex {
      display: flex;
      flex-direction: row;
    }

    .flexal {
      align-items: center;
    }

    .heaedercl {
      justify-content: space-between;
      background: #f0f0f0;
      // margin: 10px 0;
      padding: 10px;
    }

    .inputwid280 {
      width: 280px;
    }

    .marright {
      margin-right: 5px;
    }

    .marginleftcl {
      margin-left: 20px;
    }

    .inputwid160 {
      width: 160px;
    }

    .listbox {
      // max-height: 487px;
      overflow: auto;
    }

    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 240px;
    }

    /deep/ .el-input__inner:focus {
      border-color: var(--main-color);
    }
  }

  /deep/.el-button--primary {
    color: #fff;
    background-color: #5a5557;
    border-color: #5a5557;
  }
}
</style>
